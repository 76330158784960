@import '../button/button.css';

.link {
  align-items: center;
  color: var(--color-link);
  display: inline-flex;
  flex-direction: row;
  flex-grow: 0;
  text-decoration: none;
  transition: ease all 0.3s;

  &:focus {
    outline: none;
  }

  svg {
    fill: currentColor;
    flex-grow: 0;
    flex-shrink: 0;

    &:first-child {
      margin-right: 0.5rem;
    }
  }
}

.nav-link,
.nav-link:visited {
  &:focus {
    outline: none;
  }
}
