nav.menu {
  --items-h-gap: 1rem;

  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media (--tablet-) {
    flex-direction: column;
    flex-grow: 0;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
  }

  li {
    display: flex;
    margin: 0;
    padding: 0;
  }

  a,
  button {
    color: inherit;
    display: flex;
    font-size: inherit;
    text-decoration: none;
    width: 100%;
  }

  .menu-title {
    font-size: var(--font-size-small);
    padding: 0.5rem 1rem;

    &:empty {
      background-color: var(--field-border-color);
      height: 1px;
      margin: 0.5rem auto;
      padding: 0;
      width: calc(100% - 2 * var(--gap-tiny));
    }
  }

  &.horizontal {
    flex-grow: 1;
    justify-content: center;
    position: relative;

    > ul {
      display: flex;
      flex-shrink: 1;
      justify-content: center;
      max-width: 100%;
      position: relative;

      & > li {
        align-items: stretch;
        display: flex;
        flex-direction: row;
        flex-grow: 0;
        flex-shrink: 1;
        justify-content: space-between;
        max-width: max-content;
        min-width: 0;

        &:not(:last-child)::after {
          content: '';
          display: block;
          flex-grow: 1;
          flex-shrink: 1;
          max-width: var(--items-h-gap);
          min-width: 0;
          width: var(--items-h-gap);
        }

        & > a {
          flex-grow: 0;
          flex-shrink: 0;
          max-width: min-content;

          @supports (-moz-appearance: none) {
            max-width: 100%;
          }
        }
      }
    }
  }

  & > ul {
    align-items: stretch;
    flex-direction: row;
    flex-grow: 1;
    justify-content: flex-start;
    list-style: none;
    margin: 0;
    z-index: 1;

    @media (--tablet-) {
      align-items: flex-start;
      flex-direction: column;
    }

    & > li {
      align-items: center;
      flex-direction: column;
      flex-shrink: 1;
      justify-content: center;
    }
  }

  &.vertical > ul {
    flex-direction: column;
  }

  .menu-group {
    position: relative;

    & > .menu-group-toggle {
      align-items: center;
      background-color: transparent;
      border-radius: 0;
      color: inherit;
      flex-direction: row;
      height: 100%;
      padding: 0.5rem;
      text-decoration: none;
      transition: none;
      white-space: nowrap;
      width: auto;

      @media (--desktop-large) {
        padding: 0.5rem 1rem;
      }

      &:focus,
      &.open {
        background-color: transparent;
        border-color: transparent;
        border-radius: 0;
      }

      &.tablet {
        @media (--desktop) {
          display: none;
        }
        @media (--tablet-) {
          & + .submenu {
            margin: 2px;
            max-height: 0;
            overflow: hidden;
            /* transition: max-height 0.3s ease-out; */
          }

          &[aria-expanded] + .submenu {
            max-height: 100vh;
          }

          &[aria-expanded] .menu-group-toggle-pimp {
            transform: rotate(180deg);
          }
        }
      }

      &.desktop {
        @media (--tablet-) {
          display: none;
        }
      }

      & > .menu-group-toggle-pimp {
        fill: currentColor;
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .submenu {
    align-items: stretch;
    flex-direction: column;

    & > .menu-item {
      align-items: center;
      flex-direction: row;
      justify-content: flex-start;
    }

    .menu-link,
    .menu-button {
      align-items: center;
      background-color: transparent;
      border-radius: 0;
      color: inherit;
      flex-direction: row;
      font-weight: normal;
      justify-content: flex-start;
      padding: 0.5rem;
      text-decoration: none;
      transition: none;
      white-space: nowrap;

      @media (--desktop-large) {
        padding: 0.5rem 1rem;
      }

      &:focus,
      &:hover {
        background-color: var(--color-hover-highlight);
        box-shadow: none;
        color: var(--color-brand);
      }
    }
  }

  &.dropdown {
    /* stylelint-disable-next-line csstools/use-nesting */
    .menu-group:hover > .side-menu-list,
    .menu-group.open > .side-menu-list,
    .menu-group:focus-within > .side-menu-list,
    .menu-group > .menu-link:focus + .side-menu-list {
      animation: reveal 0.3s;
      animation-iteration-count: 1;
      display: flex;
      right: 0;
    }

    .side-menu-list {
      align-items: stretch;
      flex-direction: column;
      padding: 0;

      @media (--desktop) {
        background-color: var(--color-background);
        border-radius: var(--section-border-radius);
        box-shadow: var(--section-box-shadow);
        color: var(--color-text);
        display: none;
        padding: var(--gap-small) 0;
        position: absolute;
        top: 100%;
        width: 100%;
      }
    }
  }
}

.side-menu-list {
  --side-listmin-width: 16.25rem;

  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  min-width: var(--side-listmin-width);
  padding: 0;
  position: relative;

  .menu-link,
  .menu-button {
    align-items: center;
    color: inherit;
    flex-direction: row;
    font-weight: normal;
    justify-content: flex-start;
    min-height: auto;
    padding: var(--gap-tiny) var(--gap);
    text-decoration: none;
    white-space: nowrap;
    width: 100%;

    &:focus,
    &:hover {
      background-color: var(--color-hover-highlight);
      box-shadow: none;
      color: var(--color-brand);
    }
  }
}

.side-menu {
  background-color: var(--color-background);
  border-radius: var(--section-border-radius);
  box-shadow: var(--section-box-shadow);
  height: fit-content;
  padding: var(--gap-small) 0;
  width: 100%;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateY(1rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
