.hamburger-menu {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  .hamburger-toggle {
    display: none;
    height: 2rem;
    width: 2rem;
    z-index: 3;

    @media (--tablet-) {
      display: flex;
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;

      p {
        fill: currentColor;
      }
    }
  }

  @media (--tablet-) {
    .hamburger-menu-content {
      display: none;
    }

    &.open {
      .hamburger-menu-content {
        bottom: 0;
        color: inherit;
        display: flex;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: fixed;
        right: 0;
        top: 0;
      }
    }
  }
}

.noScroll {
  overflow: hidden;
}
